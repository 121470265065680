var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseContainer',[_c('span',{attrs:{"slot":"principal"},slot:"principal"},[_c('v-content',{staticClass:"pr-2 pl-2",staticStyle:{"background-color":"#555"}},[_c('v-toolbar',{staticClass:"ma-2",staticStyle:{"border-radius":"30px"}},[_c('v-btn',{attrs:{"icon":"","link":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('v-toolbar-title',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_vm._v("Acompanhamento")]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","outlined":"","single-line":"","hide-details":"","label":"Pesquise..","append-icon":"mdi-magnify"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.consultar.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('PeriodoFilter',{on:{"update":_vm.consultar},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1),_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"2"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.dados,"mobileBreakpoint":0,"loading":_vm.loading,"height":_vm.$vuetify.breakpoint.height - 230,"disable-pagination":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.database",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("emp_" + (item.database)))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.dominio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.abrirCardapio(item.dominio)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v("  "),_c('u',[_vm._v(_vm._s(item.dominio))])],1)]}},{key:"item.assinatura_id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getSubscriptionColor(item),"small":""}},[_vm._v("mdi-circle")]),_vm._v("  "),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editar(item)}}},[_c('u',[_vm._v(_vm._s(item.assinatura_id))])])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.ativo ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.ativo ? 'Ativo' : 'Inativo')+" ")])]}},{key:"item.assinatura_emissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.assinatura_emissao))+" ")]}},{key:"item.data_ultimo_pedido",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red-color': _vm.isDateOlderThan5Days(item) }},[_vm._v(" "+_vm._s(_vm.formatDate(item.data_ultimo_pedido))+" ")])]}},{key:"item.trial_started_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.trial_started_at, 'DD/MM/YYYY'))+" ")]}},{key:"item.implementacao",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"140px"},attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_criação_atualizar'),"items":_vm.status,"outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.updateSetupInfo(item.id, 'implementacao', $event)}},model:{value:(item.setup_info.implementacao),callback:function ($$v) {_vm.$set(item.setup_info, "implementacao", $$v)},expression:"item.setup_info.implementacao"}})]}},{key:"item.follow_up1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-text-field',{attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_follow_up1_data_atualizar'),"type":"date","dense":"","outlined":"","hide-details":""},on:{"change":function($event){return _vm.updateSetupInfo(item.id, 'follow_up1_data', $event)}},model:{value:(item.setup_info.follow_up1_data),callback:function ($$v) {_vm.$set(item.setup_info, "follow_up1_data", $$v)},expression:"item.setup_info.follow_up1_data"}}),_c('v-select',{staticStyle:{"width":"140px"},attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_follow_up1_status_atualizar'),"items":_vm.status,"outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.updateSetupInfo(item.id, 'follow_up1_status', $event)}},model:{value:(item.setup_info.follow_up1_status),callback:function ($$v) {_vm.$set(item.setup_info, "follow_up1_status", $$v)},expression:"item.setup_info.follow_up1_status"}})],1)]}},{key:"item.follow_up2",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-text-field',{attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_follow_up2_data_atualizar'),"type":"date","dense":"","outlined":"","hide-details":""},on:{"change":function($event){return _vm.updateSetupInfo(item.id, 'follow_up2_data', $event)}},model:{value:(item.setup_info.follow_up2_data),callback:function ($$v) {_vm.$set(item.setup_info, "follow_up2_data", $$v)},expression:"item.setup_info.follow_up2_data"}}),_c('v-select',{staticStyle:{"width":"140px"},attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_follow_up2_status_atualizar'),"items":_vm.status,"outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.updateSetupInfo(item.id, 'follow_up2_status', $event)}},model:{value:(item.setup_info.follow_up2_status),callback:function ($$v) {_vm.$set(item.setup_info, "follow_up2_status", $$v)},expression:"item.setup_info.follow_up2_status"}})],1)]}},{key:"item.conexao_whatsapp",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.setup_info.conexao_whatsapp ? 'green' : 'red'}},[_vm._v(" mdi-check-circle ")])]}},{key:"item.impressora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.setup_info.impressora)+" ")]}},{key:"item.configuracao_impressora",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"140px"},attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_configuracao_impressora_atualizar'),"items":_vm.status,"outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.updateSetupInfo(item.id, 'configuracao_impressora', $event)}},model:{value:(item.setup_info.configuracao_impressora),callback:function ($$v) {_vm.$set(item.setup_info, "configuracao_impressora", $$v)},expression:"item.setup_info.configuracao_impressora"}})]}},{key:"item.dispositivos",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getCellPhoneColor(item)}},[_vm._v("mdi-cellphone")]),_c('v-icon',{attrs:{"color":_vm.getLaptopColor(item)}},[_vm._v("mdi-laptop")])]}},{key:"item.chave_pix",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.chave_pix ? 'green' : 'red'}},[_vm._v(" mdi-check-circle ")])]}},{key:"item.cadastro_entregador",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.setup_info.cadastro_entregador ? 'green' : 'red'}},[_vm._v(" mdi-check-circle ")])]}},{key:"item.tipo_taxa_entrega",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.setup_info.tipo_taxa_entrega))])]}},{key:"item.pedidos_ultimos_7_dias_count",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.setup_info.pedidos_ultimos_7_dias_count))])]}},{key:"item.configuracao_taxa_entrega",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"140px"},attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_configuracao_taxa_entrega_atualizar'),"items":_vm.status,"outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.updateSetupInfo(item.id, 'configuracao_taxa_entrega', $event)}},model:{value:(item.setup_info.configuracao_taxa_entrega),callback:function ($$v) {_vm.$set(item.setup_info, "configuracao_taxa_entrega", $$v)},expression:"item.setup_info.configuracao_taxa_entrega"}})]}},{key:"item.horarios",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.setup_info.horarios))])]}},{key:"item.observacoes",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":250},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_menu_observacoes'),"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")]),(item.setup_info.observacoes.length)?_c('v-badge',{attrs:{"color":"green","content":item.setup_info.observacoes.length}}):_vm._e()],1)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_vm._v(" Observações "),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticStyle:{"height":"400px","overflow-x":"auto"}},_vm._l((item.setup_info.observacoes),function(obs,i){return _c('div',{key:i,staticClass:"my-2"},[_vm._v(" "+_vm._s(obs.user_name)+" em "),_c('i',[_vm._v(_vm._s(_vm.formatDate(obs.created_at)))]),_c('br'),_c('b',[_vm._v(_vm._s(obs.text))]),_c('v-divider')],1)}),0),_c('div',{staticClass:"mt-2"},[_c('v-textarea',{attrs:{"label":"Adcionar observação...","rows":"1","hide-details":"","outlined":"","dense":""},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"disabled":!_vm.checkPermission('zapercontrol_acompanhamento_observacoes_adicionar'),"loading":_vm.obsLoading,"color":"primary","block":""},on:{"click":function($event){return _vm.addObservacao(item.id)}}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" Salvar ")],1)],1)])],1)],1)]}}])}),_c('Paginate',{on:{"input":_vm.consultar},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }